/* [Components] - Buttons
------------------------------------------------------------------ */

.button {
    color: $blue;
    padding: 10px 20px;
    border: 2px solid $blue;
    text-decoration: none;
    cursor: pointer;
    display: table;
    margin: {
        left: auto;
        right: auto;
    }
    text-align: center;
    @include transition(all 150ms ease-in);
    @include border-radius(500px);

    &:hover {
        background: $blue;
        color: $white;
    }

    &--orange {
        border: 0;
        padding: 12px 22px;
        color: $default;
        @include linear-gradient(left, #fcd036 0%, #f55554 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#fcd036, endColorstr= #f55554, GradientType=1); /* IE6-9 */

        &:hover {
            color: $default;
            @include linear-gradient(left, darken(#fcd036, 10%) 0%, darken(#f55554, 10%) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=darken(#fcd036, 10%), endColorstr= darken(#f55554, 10%), GradientType=1); /* IE6-9 */
        }
    }

    &--red {
        background-color: $red;

        &:hover {
            background-color: darken($red, 10%);
        }
    }

    &--green {
        background-color: $green;

        &:hover {
            background-color: darken($green, 10%);
        }
    }
}