/* [Core] - Mixins
------------------------------------------------------------------ */

@mixin border-radius($r) {
    @include border-top-radius($r);
    @include border-bottom-radius($r);
    @include border-left-radius($r);
    @include border-right-radius($r);
}

@mixin box-shadow($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}