/* [Base] - Default
------------------------------------------------------------------ */
* {
    @include box-sizing(border-box);
}

html, body {
    min-height: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
    font-family: $ubuntu;
}

body {
    font-size: 14px;
    color: $default;
}

#main {
    overflow: hidden;
}

a, .link {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

img {
    max-width:100%;
}