.intro {
    &__title {
        font-size: 30px;
        font-size: 9.4vw;
        line-height: 36px;
        line-height: calc(36 / 30);
        padding-bottom: 5.5vw;

        @include mq($from: tablet) {
            font-size: 3.2vw;
        }

        @include mq($from: desktop) {
            font-size: 36px;
            padding-bottom: 20px;
            padding-top: 34px;
        }
    }

    &__perex {
        font-size: 5.6vw;
        line-height: calc(24 / 18);
        padding-bottom: 6.5vw;

        @include mq($from: tablet) {
            font-size: 2.2vw;
        }

        @include mq($from: wide) {
            font-size: 18px;
            padding-bottom: 29px;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        display: none;
        color: $gray-light;

        @include mq($from: tablet) {
            display: block;
        }
    }

    &__button {
        @include mq($from: tablet) {
            display: none;
        }
    }
}