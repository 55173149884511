.question {
    color: $gray-light;

    &__paragraph {
        padding-bottom: 17px;
    }

    &__link {
        color: $gray-light;
    }
}