.list {

    &--numbered {
        list-style: none;
        counter-reset: item;

        .list__item {
            position: relative;
            padding: {
                left: 15px;
                bottom: 7px;
            }

            @include mq($from: wide) {
                padding-bottom: 12px;
            }

            &:before {
                content: counter(item);
                counter-increment: item;
                color: $green;
                font-weight: 800;
                position: absolute;
                left: 0;
            }
        }
    }
}