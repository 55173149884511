.content {
    @include linear-gradient(top, #0085c5 0%, #46c4b2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0085c5, endColorstr= #46c4b2, GradientType=0); /* IE6-9 */

    @include mq($from: tablet) {
        width: calc((100vw / 5) * 3);
        padding-right: 3%;
        float: right;
        min-height: 100vh;
        background: #0085c5;
        background-image: url($imgPath + '/backgrounds/map.svg');
        background-repeat: no-repeat;
        background: url($imgPath + '/backgrounds/map.svg') 100% 0 no-repeat, -webkit-linear-gradient(top, #0085c5 0%, #46c4b2 100%);
        background: url($imgPath + '/backgrounds/map.svg') 100% 0 no-repeat, linear-gradient(top, #0085c5 0%, #46c4b2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0085c5, endColorstr= #46c4b2, GradientType=0);
        background-size: cover;
        background-attachment: fixed;
    }

    @include mq($from: wide) {
        width: calc(100vw - 430px);
        padding-right: 5%;
    }

    @include mq($from: 1441) {
        //width: calc(100% - 455px);
    }

    &--detail, &--form {
        @include mq($from: tablet) {
            //width: calc((100vw / 5) * 3);
        }

        @include mq($from: 1441) {
            //width: calc(100% - 455px);
        }
    }

    &--form {
        @include mq($from: wide) {
            width: calc(100vw - 340px);
        }
    }
}