.overview {

    &__mobileImage {
        margin :{
            right: -20px;
            left: -20px;
            bottom: 24px;
        }

        @include mq($from: tablet) {
            display: none;
        }
    }

    &__title {
        font-size: 7.5vw;
        line-height: calc(28/24);
        padding-bottom: 6vw;

        @include mq($from: tablet) {
            font-size: 3.125vw;
        }

        @include mq($from: desktop) {
            font-size: 24px;
            padding-bottom: 15px;
        }
    }

    &__separator {
        width: 100%;
        height: 2px;
        background: #e3e3e3;
        margin: 15px 0;
    }

    &__summary {
        line-height: calc(30/18);
        font-size: 4.375vw;
        padding-bottom: 10vw;

        @include mq($from: tablet) {
            font-size: 1.823vw;
            padding-bottom: 5vw;
        }

        @include mq($from: desktop) {
            font-size: 14px;
            padding-bottom: 32px;
        }

        strong {
            font-size: 5.625vw;
            @include mq($from: tablet) {
                font-size: 2.34375vw;
            }

            @include mq($from: desktop) {
                font-size: 18px;
            }
        }
    }

    &__button {
        margin: {
            left: 0;
            right: 0;
            bottom: 10vw;
            top: 25px;
        }

        @include mq($from: tablet) {
            margin: {
                bottom: 5vw;
            }
        }

        @include mq($from: desktop) {
            margin: {
                bottom: 38px;
            }
        }
    }

    &__question {
        color: $gray-light;
    }
}