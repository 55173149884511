.header {
    background-color: $white;
    @include mq($from: tablet) {
        min-height: 100vh;
        position: fixed;
        z-index: 2;
        width: calc((100vw / 5) * 2);
        padding-left: 3%;
        @include box-shadow(0 0 20px rgba($black, .2));
    }

    @include mq($from: wide) {
        padding-left: 5%;
        width: 430px;
    }

    @include mq($from: 1441) {
        //width: 455px;
    }
    
    &--detail {
        background-color: #fcfcfc;
        @include box-shadow(none);

        @include mq($from: tablet) {
            //width: calc(100% / 5 * 2);
        }

        @include mq($from: 1441) {
            //width: 455px;
        }

        @include mq($from: tablet, $and: '(max-height: 800px)') {
            height: 100vh;
            overflow-y: scroll;
        }
    }

    &--form {
        background-color: $white;
        @include box-shadow(none);

        @include mq($from: wide) {
            width: 340px;
        }
    }

    &__in {
        padding: {
            left: 20px;
            right: 20px;
            bottom: 18px;
            top: 55px;
        }

        @include mq($from: tablet) {
            padding: {
                left: 0;
            }
        }

        @include mq($from: wide) {
            padding: {
                //left: 0;
                //right: 0;
            }
        }

        &--detail, &--form {
            padding: {
                top: 18px;
            }
        }
    }

    &__wrap {
        @include mq($from: wide) {
            //width: 356px;
            //float: right;
        }

        &--detail {
            @include mq($from: desktop) {
                //width: 356px;
                //float: right;
            }
        }
    }

    &__bottom {
        display: none;
        @include mq($from: tablet) {
            display: block;
            position: absolute;
            bottom: 40px;
            //left: 0;
            padding: {
                //left: 20px;
                right: 20px;
            }
        }

        @include mq($from: wide) {
            //left: auto;
            //right: 0;
            //width: 356px;
            padding: {
                //left: 0;
                //right: 0;
            }
        }

        @include mq($from: tablet, $and: '(max-height: 800px)') {
            position: relative;
            bottom: auto;
            margin-top: 20px;
        }
    }
}