.notFound {
    color: $gray-light;

    &--footer {
        @include mq($from: tablet) {
            display: none;
        }
    }

    &__title {
        font-weight: 300;

        @include mq($from: wide) {
            font-size: 18px;
        }
    }

    &__paragraph {
        padding-bottom: 17px;
    }

    &__button {
        margin-bottom: 17px;
        @include mq($from: tablet) {
            margin-left: 0;
        }
    }

    &__link {
        color: $gray-light;
    }
}