.card {
    @include border-radius(8px);
    background-color: $white;
    @include box-shadow(0 0 17px rgba(#103332, .17));
    margin-bottom: 20px;
    width: 100%;
    display: block;
    color: $default;
    @include transition(all 250ms ease-in);
    text-decoration: none;

    @include mq($from: wide) {
        margin-bottom: 36px;
    }

    &__imageWrap {
        @include border-top-radius(8px);
        overflow: hidden;
    }

    &__image {
        @include size(100% auto);
        @include transition(all 150ms ease-in);
    }

    &__header {
        padding: 12px 10px 22px;
        position: relative;
        @include clearfix;
    }

    &__text {
        @include mq($from: desktop) {
            float: left;
        }

        &--big {
            @include mq($from: desktop) {
                width: calc(100% - 80px);
            }
        }
    }

    &__title {
        @include transition(all 250ms ease-in);
        font-size: 5.6vw;
        padding-bottom: 1vw;

        @include mq($from: tablet) {
            font-size: 2.35vw;
        }

        @include mq($from: wide) {
            font-size: 18px;
        }

        &--big {
            @include mq($from: tablet) {
                font-size: 3.1vw;
            }

            @include mq($from: desktop) {
                padding-right: 0;
            }

            @include mq($from: wide) {
                font-size: 24px;
            }
        }

        a {
            text-decoration: none;
            @include transition(all 250ms ease-in);
            color: $default;
        }
    }

    &__paragraph {
        font-size: 4.35vw;
        line-height: calc(18 / 14);

        @include mq($from: tablet) {
            font-size: 1.8vw;
        }

        @include mq($from: tablet) {
            font-size: 14px;
        }

        a {
            color: $default;
            text-decoration: none;
            @include transition(all 250ms ease-in);
        }
    }

    &__info {
        border-top: 1px solid #dddde8;
        @include clearfix;
        padding: 35px 10px 15px;
        color: #8f8f96;

        @include mq($from: desktop) {
            padding-top: 15px;
        }

        &--big {
            @include mq($from: tablet) {
                .card__infoItem {
                    width: calc(100%/3);

                    &--where {
                        display: inline-block;
                    }

                    &:nth-of-type(2n) {
                        display: inline-block;
                        text-align: center;
                    }
                }
            }
        }
    }
    &__infoItem {
        width: 50%;
        display: inline-block;
        float: left;
        font-size: 4.35vw;
        @include mq($from: tablet) {
            font-size: 14px;
        }

        &:last-of-type {
            text-align: right;
        }

        &--where {
            display: none;
        }
    }

    &__mobileButton {
        margin-bottom: -20px;
        position: relative;
        z-index: 2;
        background-color: $white;

        @include mq($from: desktop) {
            display: none;
        }
    }

    &__overlay {
        @include position(absolute, 0);
        background-color: rgba($blue, .3);
        z-index: 2;
        opacity: 0;
        @include transition(all 250ms ease-in);
        display: none;

        @include mq($from: desktop) {
            display: block;
        }
    }

    &__overlayButton {
        position: absolute;
        left: 50%;
        top: 50%;
        @include transition(all 250ms ease-in);
        @include transform(translateX(-50%) translateY(-50%) scale(1.2));
        background-color: $white;
        border: 0;
    }

    &:hover {
        @include box-shadow(0 0 17px rgba(#bfeaff, 1));

        .card {
            &__title {
                color: $blue;
                a {
                    color: $blue;
                }
            }
            &__overlay {
                opacity: 1;
            }
            &__overlayButton {
                @include transform(translateX(-50%) translateY(-50%) scale(1));
            }
        }
    }
}