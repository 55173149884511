.row {
    @include clearfix;

    &--form {
        max-width: 400px;
    }
}

.col {
    float: left;

    &--6 {
        width: 49%;
        margin-right: 2%;
    }

    &:last-of-type {
        margin-right: 0 !important;
    }
}