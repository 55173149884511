@mixin calculate($width, $height, $x, $y) {
    width: $width/2;
    height: $height/2;
    background-position: ($x/2) ($y/2);
}

@mixin divide_bg($w, $h) {
    $new_w: $w/2;
    $new_h: $h/2;

    background-size: #{$new_w}px #{$new_h}px;
}

// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;

@mixin sprites($name) {
    background-image: url($imgPath + 'sprites.png' + '?v=' + $random);
    background-repeat: no-repeat;
    @include divide_bg(125, 80);
    display: inline-block;
    content: '';

    @if $name == "arrow-backlink-white" { @include calculate(40px, 22px, -85px, 0px); }
    @if $name == "arrow-backlink" { @include calculate(40px, 22px, -85px, -27px); }
    @if $name == "close" { @include calculate(22px, 22px, -85px, -54px); }
    @if $name == "icon-fb" { @include calculate(80px, 80px, 0px, 0px); }
}


