.nav {
    &--detail {
        display: none;

        @include mq($from: tablet) {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            padding: 50px 0 15px 20px;
            z-index: 3;
            border-bottom: 1px solid transparent;
            @include transition(padding 250ms ease-in, background 250ms ease-in, border 250ms ease-in);
        }

        @include mq($from: desktop) {
            padding-left: 55px;
            padding-top: 70px;
        }

        @include mq($from: wide) {
            padding-left: calc(100vw/1140*55);
        }

        &.animated {
            position: fixed;
            display: block;
            left: auto;
            width: 55vw;
            width: calc(((100vw / 5) * 3) - 3%);
            background-color: rgba($white, .95);
            padding-top: 18px;
            margin-left: -20px;
            border-bottom: 1px solid #f1f1f1;

            @include mq($from: desktop) {
                margin-left:-55px;
                //max-width:506px;
            }

            @include mq($from: wide) {
                width: calc(95vw - 430px);
                margin-left: calc(-100vw/1140*55);
                max-width: 800px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            display: inline-block;
            margin-right: 15px;

            &:last-child {
                margin-right: 0 !important;
            }

            @include mq($from: desktop) {
                margin-right: 4vw;
            }

            @include mq($from: 1920) {
                margin-right: 90px;
            }
        }

        a {
            color: $default;
            text-decoration: none;

            &:hover {
                color: $blue;
                border-bottom: 4px solid $blue;
            }

            &.active {
                border-bottom: 4px solid $blue;
                color: $blue;
                font-weight: 700;
            }
        }
    }
}