.createTrip {
    background-color: $white;
    padding: {
        left: 20px;
        right: 20px;
        top: 35px;
    }
    position: relative;

    @include mq($from: tablet) {
        min-height: 100vh;
        @include box-shadow(0 0 20px rgba($black, .2));
        padding-bottom: 60px;
    }

    @include mq($from: desktop) {
        padding: {
            left: 55px;
            right: 55px;
        }
    }

    @include mq($from: wide) {
        max-width: 900px;
        padding: {
            left: calc(100vw / 1140 * 55);
            right: calc(100vw / 1140 * 55);
        }
    }
}