/* [Base] - Text
------------------------------------------------------------------ */
h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
}


.title {
    &--secondary {
        font-weight: 300;
        color: $orange;
        font-size: 7.5vw;

        @include mq($from: tablet) {
            font-size: 3.125vw;
        }

        @include mq($from: desktop) {
            font-size: 24px;
        }

        @include mq($from: 1441) {
            font-size: 28px;
        }
    }
}