.footer {
    @include mq($from: tablet) {
        display: none;
    }

    &__in {
        padding: {
            left: 20px;
            right: 20px;
            top: 37px;
        }
    }
}