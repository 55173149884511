.userBox {
    position: absolute;
    right: 10px;
    top: -30px;
    cursor: default;

    @include mq($until: desktop) {
        display: none;
    }

    &--big {
        @include mq($from: desktop) {
            @include position(relative, auto);
            float: right;
        }
    }

    &__bagde {
        position: absolute;
        right: 0;

        &--big {
            @include mq($from: desktop) {
                position: static;
            }
        }
    }

    &__badgeImage {
        @include border-radius(50%);
        @include size(40px);

        &--big {
            @include mq($from: desktop) {
                @include size(80px);
            }
        }
    }

    &__info {
        text-align: center;

        @include mq($from: tablet) {
            text-align: left;
        }
    }

    &__name {
        color: $blue;
        font-weight: 300;
        font-size: 7.5vw;
        padding-bottom: 2.875vw;

        @include mq($from: tablet) {
            font-size: 18px;
            padding-bottom: 15px;
        }
    }

    &__text {
        font-size: 5vw;
        line-height: calc(21 / 16);
        margin-bottom: 20px;

        @include mq($from: tablet) {
            font-size: 14px;
        }
    }

    &__wrap {
        padding: 18px 0 18px 18px;
        z-index: 3;
        background-color: $white;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        @include transition(all 250ms ease-in);

        @include mq($from: tablet) {
            position: absolute;
            right: -16px;
            bottom: 100%;
            left: auto;
            height: auto;
            top: auto;
            width: 270px;
            background-color: #f8f8f8;
            @include border-radius(6px);
            @include box-shadow(0 0 20px rgba($black, .17));

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -8px;
                right: 30px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;

                border-top: 8px solid #f8f8f8;
            }
        }

        &--big {
            @include mq($from: tablet) {
                right: 2px;
            }
        }

        &.opened {
            visibility: visible;
            opacity: 1;
        }
    }

    &__content {
        max-height:100%;

        @include mq($from: tablet) {
            min-height: 140px;
            max-height: 250px;
            overflow: hidden;
        }

        .post {
            display: none;
            @include mq($from: tablet) {
                display: inline;
                font-size: 14px;
            }

            &--1 {
                display: inline;
                font-weight: 700;
                @include mq($from: tablet) {
                    font-weight: 300;
                }
            }
        }

        .pre {
            @include mq($from: tablet) {
                display: none;
            }
        }
    }

    &__userImageWrap {
        position: relative;
        display: table;
        margin: {
            left: auto;
            right: auto;
            bottom: 15px;
        }

        @include mq($from: tablet) {
            display: none;
        }
    }

    &__userFb {
        @include sprites(icon-fb);
        position: absolute;
        z-index: 2;
        bottom: -5px;
        right: -5px;
    }

    &__close {
        color: $blue;
        text-align: right;
        padding: 0 0 35px;
        text-decoration: underline;

        &:after {
            margin-left: 13px;
            @include sprites(close);
        }

        @include mq($from: tablet) {
            display: none;
        }
    }

    &__userStatistic {
        color: #8f8f96;
        font-size: 5vw;

        @include mq($from: tablet) {
            text-align: center;
            @include clearfix;
            font-size: 16px;
        }
    }

    &__userStatisticItem {
        margin-bottom: 20px;

        @include mq($from: tablet) {
            margin-bottom: 0;
            float: left;
            width: 50%;
        }

        strong {
            @include mq($from: tablet) {
                font-size: 24px;
                display: block;
            }
        }
    }
}

.mCSB_inside>.mCSB_container {
    margin-right: 25px !important;
}