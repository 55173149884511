.detail {
    background-color: $white;
    padding: {
        left: 20px;
        right: 20px;
        top: 35px;
    }
    position: relative;
    overflow: hidden;

    ul {
        padding-left: 20px;
        margin: -5px 0 30px;
    }

    @include mq($from: tablet) {
        //width: 55vw;
        min-height: 100vh;
        padding-top: 0;
        @include box-shadow(0 0 20px rgba($black, .2));
        padding-bottom: 60px;
    }

    @include mq($from: desktop) {
        padding: {
            left: 55px;
            right: 55px;
        }
    }

    @include mq($from: wide) {
        max-width: 800px;
        padding: {
            left: calc(100vw/1140*55);
            right: calc(100vw/1140*55);
        }
    }

    &__image {
        display: none;
        @include mq($from: tablet) {
            display: block;
            margin: {
                left: -20px;
                right: -20px;
                bottom: 20px;
            }
        }

        @include mq($from: desktop) {
            margin: {
                left: -55px;
                right: -55px;
                bottom: 55px;
            }
        }

        @include mq($from: wide) {
            margin-right: calc(-100vw/1140*55);
            margin-left: calc(-100vw/1140*55);
        }
    }

    &__item {
        margin-bottom: 15.625vw;

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 20px;
        }

        @include mq($from: tablet) {
            margin-bottom: 7.3125vw;
        }

        @include mq($from: desktop) {
            @include clearfix;
            margin-bottom: 20px;
        }
    }

    &__item {
        max-width: 600px;

        &Inner {
            max-width: 600px;
        }
    }

    h2 {
        color: $gray-light;
        font-size: 5vw;
        padding-bottom: 3vw;

        @include mq($from: tablet) {
            font-size: 2.083vw;
            padding-bottom: 1.4vw;
        }

        @include mq($from: desktop) {
            font-size: 16px;
            padding-bottom: 8px;
        }

        @include mq($from: 1441) {
            font-size: 18px;
            padding-bottom: 12px;
        }
    }

    &__text, p {
        line-height: calc(21/16);
        font-size: 5vw;
        padding-bottom: 2vw;
        word-wrap: break-word;

        @include mq($from: tablet) {
            font-size: 2.083vw;
            padding-bottom: 1vw;
        }

        @include mq($from: desktop) {
            font-size: 16px;
            padding-bottom: 8px;
        }

        @include mq($from: 1441) {
            font-size: 18px;
            padding-bottom: 12px;
        }
    }

    &__userName {
        color: $blue;
        font-size: 7.5vw;
        padding-bottom: 8px;

        @include mq($from: tablet) {
            font-size: 3.125vw;
        }

        @include mq($from: desktop) {
            font-size: 24px;
        }
        @include mq($from: 1441) {
            font-size: 28px;
        }
    }

    &__userStatistic {
        color: $gray-light;
        font-size: 5vw;
        margin-top: 13px;

        @include mq($from: tablet) {
            font-size: 2.083vw;
        }

        @include mq($from: desktop) {
            font-size: 16px;
        }

        @include mq($from: 1441) {
            font-size: 18px;
        }
    }

    &__userStatisticItem {
        margin-bottom: 20px;

        @include mq($from: desktop) {
            display: inline-block;
        }

        &:first-of-type {
            margin-right: 30px;
        }
    }

    &__userImageWrap {
        position: relative;
        display: table;
        margin: {
            left: auto;
            right: auto;
            bottom: 15px;
        }
    }

    &__transport {
        font-size: 5.265vw;

        @include mq($from: tablet) {
            font-size: 2.34375vw;
        }

        @include mq($from: desktop) {
            font-size: 18px;
        }
    }

    &__userImage {
        @include border-radius(50%);
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    &__userFb {
        @include sprites(icon-fb);
        position: absolute;
        z-index: 2;
        bottom: -5px;
        right: -5px;
    }

    &__textLeft {
        @include mq($from: desktop) {
            float: left;
            width: calc(100% - 100px);
        }

        @include mq($from: 1441) {
            width: calc(100% - 120px);
        }
    }

    &__textRight {
        @include mq($from: desktop) {
            float: right;
            margin-right: -30px;
        }

        @include mq($from: 1260) {
            margin-right: -80px;
        }
    }

    &__form {
        margin: {
            left: -20px;
            right: -20px;
        }
        padding: {
            left: 20px;
            right: 20px;
            top: 30px;
            bottom: 30px;
        }
        background-color: #fff7d2;

        @include mq($from: tablet) {
            @include border-radius(10px);

            margin: {
                left: 0;
                right: 0;
            }
        }

        @include mq($from: desktop) {
            margin: {
                right: -25px;
                left: -25px;
            }
            padding: {
                left: 25px;
                right: 25px;
            }
        }

        h3 {
            font-weight: 300;
            color: $orange;
            font-size: 7.5vw;

            @include mq($from: tablet) {
                font-size: 3.125vw;
            }

            @include mq($from: desktop) {
                font-size: 24px;
            }

            @include mq($from: 1441) {
                font-size: 28px;
            }
        }

        p {
            padding-bottom: 20px;

            span {
                display: none;
            }
            strong, span {
                @include mq($from: tablet) {
                    display: inline-block;
                }
            }
        }
    }

    &__backlink {
        padding: {
            top: 20px;
            left: 20px;
            right: 20px;
        }
        background-color: #0085c5;
        @include linear-gradient(-45deg, #0085c5 0%, #46c4b2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0085c5, endColorstr= #46c4b2, GradientType=1);

        @include mq($from: tablet) {
            display: none;
        }
    }
}