/* [Components] - Custom scrollbar
------------------------------------------------------------------ */

.mCS-customScroll {
    .mCSB_draggerRail {
        width: 8px;
        background-color: #e1e1e1;
    }

    .mCSB_draggerContainer {
        .mCSB_dragger {
            .mCSB_dragger_bar {
                width: 8px;
                background-color: $blue;
            }
        }
    }
}