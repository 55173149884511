.box {
    &--white {
        background-color: $white;
    }

    &--yellow {
        background-color: #fff7d2;
    }

    &--offset {
        padding: 20px;
    }

    &--shadow {
        @include box-shadow(0 0 5px rgba(#103332, .17));
    }

    &--rounded {
        @include border-radius(8px);
    }
}