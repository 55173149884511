.form {
    *:focus {
        outline: none !important;

    }

    &__item {
        margin-bottom: 25px;
    }

    label {
        cursor: pointer;
    }

    &__label {
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 5vw;

        @include mq($from: tablet) {
            font-size: 2.083vw;
        }

        @include mq($from: desktop) {
            font-size: 16px;
        }

        &--image {
            cursor: pointer;
            display: inline-block;
            font-weight: 300;

            & + input {
                opacity: 0;
                visibility: hidden;
                width: 0 !important;
                height: 0 !important;
                margin: 0;
                padding: 0 !important;
                position: absolute;
                overflow: hidden;
            }
        }

        span {
            font-weight: 300;
            margin-top: 5px;
            display: inline-block;
            font-size: 4.375vw;
            line-height: calc(21 / 14);

            @include mq($from: tablet) {
                font-size: 1.823vw;
            }

            @include mq($from: desktop) {
                font-size: 14px;
            }
        }
    }

    &__placeholder {
        max-width: 600px;
        font-size: 12px;
        margin: -5px 0 8px;
        line-height: 18px;

        &--date {
            color: $gray-light;
            margin-top: 5px;
        }
    }

    &__input {
        &:not(.button):not(.form__input--checkbox) {
            border: 2px solid #fccd32;
            @include border-radius(20px);
            @include size(100% 40px);
            max-width: 400px;
            padding: 0 20px;
            @include transition(all 150ms ease-in);

            &:focus, &:active, &:hover {
                border-color: $orange;
            }
        }

        &--price, &--count {
            max-width: 100px !important;
        }

        &--email {
            max-width: 220px !important;
        }

        &--button {
            margin-left: 0;
        }

        // checkbox
        &--checkbox {
            opacity: 0;
            position: absolute;

            & + label {
                position: relative;
                display: block;
                padding-left: 30px;

                &::before {
                    @include border-radius(3px);
                    content: '';
                    @include size(24px);
                    display: block;
                    position: absolute;
                    border: 2px solid $yellow;
                    left: 0;

                    @include mq($from: desktop) {
                        @include size(18px);
                        top: -2px;
                    }
                }
            }

            &:focus + label::before {
                border-color: $orange;
            }

            &:checked + label {

                &::before {
                    border-color: $orange;
                }
                &::after {
                    border-right: 3px solid $orange;
                    border-bottom: 3px solid $orange;
                    @include size(7px 14px);
                    content: '';
                    display: block;
                    position: absolute;
                    left: 8px;
                    top: 4px;
                    @include transform(rotateZ(45deg));

                    @include mq($from: desktop) {
                        @include size(6px 11px);
                        left: 6px;
                        top: 1px;
                    }
                }
            }
        }
    }

    &__textarea {
        border: 2px solid #fccd32;
        @include border-radius(20px);
        width: 100%;
        max-width: 600px;
        min-height: 100px;
        padding: 20px;
        resize: none;
        @include transition(all 150ms ease-in);

        &--description {
            min-height: 200px;
        }

        &:focus, &:active, &:hover {
            border-color: $orange;
        }
    }

    &__button {
        @extend .button;
        font-size: 16px;
        display: block;
        margin: {
            left: 0;
            right: 0;
        }
    }

    &__fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    &__thx {
        padding-top: 22px;

        h1 {
            padding-bottom: 10px;
        }
        p {
            padding-bottom: 7px;
        }
    }
}

.form__errors, .errorMsg {
    color: $red;
    font-weight: 800;
    padding: 8px 0;
}

.errors {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($red, .9);
    text-align: center;
    list-style: none;
    padding: 30px;
    z-index: 10;
    font-size: 24px;
    color: $white;
    font-weight: 800;
}

.flashMessage {
    color: $default;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($green, .9);
    text-align: center;
    list-style: none;
    padding: 30px;
    z-index: 10;
    font-size: 24px;
    font-weight: 800;
}

button, input[type='submit'], input[type='button'] {
    &[disabled], &:disabled {
        background: #ddd none;

        &:hover {
            background: #ddd none;
        }
    }
}