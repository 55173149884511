.itinerar {
    &__item {
        padding-bottom: 15px;
        font-size: 5vw;
        line-height: calc(21 / 16);

        @include mq($from: tablet) {
            font-size: 2.083vw;
            display: table;
        }

        @include mq($from: desktop) {
            font-size: 16px;
        }
    }

    &__day, &__desc {
        @include mq($from: tablet) {
            display: table-cell;
        }
    }

    &__day {
        white-space: nowrap;
        padding-right: 10px;
    }
}

.pricing {
    color: $blue;
    margin-bottom: 15px;

    &__number {
        font-size: 15vw;

        @include mq($from: tablet) {
            font-size: 6.25vw;
        }

        @include mq($from: desktop) {
            font-size: 48px;
        }
    }

    &__currency {
        font-size: 5.625vw;

        @include mq($from: tablet) {
            font-size: 3.125vw;
        }

        @include mq($from: desktop) {
            font-size: 24px;
        }
    }

    &__label {
        font-size: 4.375vw;
        color: $gray-light;

        @include mq($from: tablet) {
            font-size: 1.823vw;
        }

        @include mq($from: desktop) {
            font-size: 14px;
        }
    }
}

.fullTrip {
    font-size: 7vw;
    color: #f55958;
    padding-bottom: 1vw;

    @include mq($from: tablet) {
        font-size: 2.083vw;
        padding-bottom: .4vw;
    }

    @include mq($from: desktop) {
        font-size: 20px;
        padding-bottom: 2px;
    }

    @include mq($from: 1441) {
        font-size: 24px;
        padding-bottom: 4px;
    }
}

.trips {
    &__title {
        color: $white;
        font-size: 8vw;
        padding-bottom: 4vw;
        padding-left: 7px;

        @include mq($from: tablet) {
            font-size: 4vw;
            padding-bottom: 2vw;
        }

        @include mq($from: desktop) {
            font-size: 36px;
            padding: {
                bottom: 20px;
            }
        }
    }
}

.list__form, .interest {
    p {
        padding-bottom: 20px;
        font-size: 16px;
    }
}

.list__form {
    max-width: 570px;
    margin: {
        left: auto;
        right: auto;
    }

    @include mq($from: tablet) {
        margin-left: 20px;
    }

    @include mq($from: wide) {
        margin-left: auto;
    }

    form {
        @include mq($from: tablet) {
            position: relative;
            padding-bottom: 40px;
        }
    }

    .form__item {
        &--rules {
            @include mq($from: tablet) {
                position: absolute;
                bottom: 0;

                .errorMsg {
                    position: absolute;
                    white-space: nowrap;
                }
            }
        }

        &--field {
            @include mq($from: tablet) {
                float: left;
                width: calc(100% - 125px);

                input {
                    max-width: 100% !important;
                    height: 42px !important;
                }

                label {
                    display: none;
                }
            }
        }
        &--button {
            @include mq($from: tablet) {
                float: right;
                margin-left: -80px;
            }
        }
    }
}

.interest {
    &__inner {
        max-width: 446px;
    }

    p {
        @include mq($from: desktop) {
            font-size: 18px;
        }
    }

    .form__input--email {
        max-width: 100% !important;
    }
}

.nobreak {
    white-space: nowrap;
}

.joinInterest {
    display: inline-block;
    text-align: left;
    max-width: 560px;
    margin: 50px 20px 0 20px;
    font-size: 18px;
    font-weight: bold;
    color: $orange;

    @include mq($from: tablet) {
        margin-right: 0;
        font-size: 24px;
        font-size: 3.2vw;
    }

    @include mq($from: desktop) {
        margin-left: 36px;
    }

    @include mq($from: wide) {
        font-size: 24px;
        margin-top: 90px;
        margin-bottom: 40px;
        padding: 35px;
    }
}