.trips {
    background: transparent url($imgPath + '/backgrounds/ajax-loader.gif') center 33vh no-repeat;

    @include mq($from: tablet) {
        background-position: center 50vh;
    }

    &.loaded {
        background: transparent none;
    }

    padding:  {
        top: 40px;
        bottom: 40px;
        left: 20px;
        right: 20px;
    }

    @include mq($from: tablet) {
        padding: {
            right: 0;
        }
    }

    @include mq($from: wide) {
        padding: {
            left: 36px;
        }
    }

    @include clearfix
}

.grid-sizer {
    width: 100%;

    @include mq($from: desktop) {
        width: calc(100% / 3);
    }

    @include mq($from: 1600px) {
        width: calc(25%);
    }

    @include mq($from: 1920px) {
        width: calc(20%);
    }
}

.grid {
    margin-left: -20px;
    opacity: 0;
    @include transition(all 250ms ease-in);

    &.visible {
        opacity: 1;
    }

    @include mq($from: wide) {
        margin-left: -36px;
    }
}

.ie9 .grid {
    opacity: 1;
}

.grid-item {
    float: left;
    width: 100%;
    padding-left: 20px;

    @include mq($from: desktop) {
        width: calc(100% / 3);

        &--width3 {
            width: calc(100% / 3 * 2);
        }
    }

    @include mq($from: wide) {
        padding-left: 36px;
    }

    @include mq($from: 1600px) {
        width: calc(25%);

        &--width3 {
            width: calc(50%);
        }
    }

    @include mq($from: 1920px) {
        width: calc(20%);

        &--width3 {
            width: calc(40%);
        }
    }
}