.backlink {
    color: $blue;
    text-decoration: underline;
    margin-bottom: 21px;
    display: inline-block;

    &:hover {
        text-decoration: none;
        &:before {
            left: -5px;
        }
    }

    &:before {
        @include transition(all 150ms ease-in);
        @include sprites(arrow-backlink);
        left: 0;
        text-indent: -9999px;
        position: relative;
        margin-right: 15px;
    }

    &--white {
        color: $white;

        &:before {
            @include sprites(arrow-backlink-white);
        }
    }
}