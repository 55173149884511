/* [Core] - Variables
------------------------------------------------------------------ */

// Base Color
$black: #000;
$default: #3c3c3f;
$gray-light: #b7b7bc;
$white: #fff;

// Colors
$green: #cad000;
$blue: #0085c4;
$red: #f55958;
$yellow: #fcd035;
$orange: #f88d44;

// Settings
$borderRadius: 5px;

// Font
$ubuntu: 'Ubuntu', 'Helvetica', 'Arial', sans-serif;